import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Icon, Typography, Tooltip } from '@mui/material';
import { StoreUtil, SpecialtyUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable, ProvidersTabs } from 'components';
import { favoritesByType } from 'store/actions/favoritesActions';
import { Helmet } from 'react-helmet';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
});

class FavoriteProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Provider
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'NPI',
        key: 'npi',
      },
      {
        label: 'Specialty',
        key: 'taxonomy.classification',
        
        format: (classification, row) => {
          return SpecialtyUtil.getNameFromTaxonomy(row.taxonomy);
        },
      },
      {
        label: 'City',
        key: 'location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];

    this.state = {
      query: {
        include: [
          {
            association: 'taxonomy',
          },
          {
            attributes: ['id', 'city', 'state'],
            association: 'location',
          },
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId
      || this.props.userId !== prevProps.userId) {
      this.fetchData();
    }
  }

  fetchData() {
    if (!StoreUtil.isLoaded(this.props.favorites)) {
      this.props.dispatch(favoritesByType(
        this.props.clientId,
        this.props.userId,
        'Providers',
      ));
    }
  }


  render() {
    const { classes } = this.props;

    const { query } = this.state;

    const favorites = this.props.favorites;

    var favoritesData = [];

    if (favorites.data) {
      for (let i = 0; i < favorites.data.rows.length; i++) {
        favoritesData.push(favorites.data.rows[i].provider);
      }
    }

    return (
      <div className={classes.container}>
        <Helmet defer={false}>
        <title>Providers</title>
        </Helmet>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>Providers</Typography>

        </div>
        <ProvidersTabs tab='favorite_providers' />
        <div className={classes.container}>

          <DynamicTable
            header='Favorite Providers'            
            showFilters={false}
            columns={this.columns}
            query={query}
            data={favoritesData}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/providers/${row.id}`}
          />
        </div>
      </div>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };
}

FavoriteProvidersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  favorites: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const { favorites } = state;
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  console.log(StoreUtil.get(favorites, 'by_type', `${clientId}-${userId}-Providers`));


  return {
    userId,
    clientId,
    favorites: StoreUtil.get(favorites, 'by_type', `${clientId}-${userId}-Providers`),
   
  };
}

const styled = withStyles(styles)(FavoriteProvidersPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as FavoriteProvidersPage };
