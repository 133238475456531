import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Icon, Typography, Tooltip, Button } from '@mui/material';
import { ProviderAddDialog } from 'components';
import { Helmet } from 'react-helmet';
import { StoreUtil, SpecialtyUtil } from 'doctivity-shared/utils';
import axiosInstance from 'utils/axiosUtil';
import { DynamicTable, ProvidersTabs } from 'components';
import { queryTaxonomyCodes } from 'store/actions/taxonomyActions';
import {
  queryProviders,
  API_PROVIDERS_LIST,
} from 'store/actions/providersActions';
import { upsertProvider } from 'store/actions/providersActions';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },

  smallHeaderTitle: {
    color: '#FFFFFF',
    fontSize: 14,
    paddingRight: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  addProviderButton: {
    marginRight: theme.spacing(1),
  },
});

class ProvidersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      label: '',
      query: {
        attributes: ['id', 'first_name', 'last_name', 'npi'],
        include: [
          {
            association: 'taxonomy',
            attributes: ['classification','specialization','doctivity_name'],
            require: false,
          },
          {
            attributes: ['id', 'city', 'state'],
            association: 'location',
            require: false,
          },
        ],
        order: [['id', 'DESC']],
        //where: {primary_taxonomy: ['207Q00000X']},
        limit: 25,
        offset: 0,
      },
    };

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated > 0) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Provider
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'Specialty',
        key: 'taxonomy.classification',
        filterFields: ['primary_taxonomy'],

        format: (classification, row) => {
          return SpecialtyUtil.getNameFromTaxonomy(row.taxonomy);
        },
        specialtySearch: true
      },
      {
        label: 'City',
        key: 'location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];

   // this.columns[4].onFilterClick = () => {
     // this.showPicker();
    //};
  }

  getLast(clientId, callback) {
    axiosInstance
      .get('/Providers', {
        params: {
          opts: {
            where: {},
            order: [['id', 'DESC']],
            limit: 1,
            offset: 0,
          },
        },
      })
      .then((response) => {
          callback(response.data.rows[0].id);
      });
  }

  componentDidMount() {
    this.fetchData();

    let values = JSON.parse(sessionStorage.getItem('selectedSpecialties'));

    if (values && values.length > 0) {
      this.fetchSpecialties(values);
    } else {
      this.taxonomyCodes = [];
    }
    const oldQuery = sessionStorage.getItem('providersTableQuery');
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery));
    }
  }

  componentDidUpdate(prevProps) {
    const { providers } = this.props;
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.providers) &&
      StoreUtil.isLoaded(providers)
    ) {
      this.fetchAffiliated();
    }
  }

     
  newProvider = () => {
    this.setState({ 
      isAdding: true,
      newProvider: {
        first_name: '',
        middle_name: '',
        last_name: '',
        npi: null,
        credential: null,
        phone: null,
        mobile_phone: null,
        email: null,
        is_affiliated: false,
        is_specialist: false,
        rvu_goal: null,
        primary_taxonomy: null,
      }
    });
  }

  onQueryChange = (query) => {
    if (query.where && Object.keys(query.where).length === 0) {
      delete query.where;
    }
    sessionStorage.setItem('providersTableQuery', JSON.stringify(query));
    this.setState({ query }, this.fetchData.bind(this));


  };

  fetchData() {
    const { dispatch, affiliatedProviders } = this.props;

    const { query } = this.state;

    StoreUtil.setInvalid(affiliatedProviders, StoreUtil.COMMON_LIST);
    dispatch(
      queryProviders({
        ...query,
      })
    );
  }

  fetchSpecialties(values) {
    const { dispatch } = this.props;

    let queryCodes = {
      where: { code: values},
      order: [['code', 'ASC']],
      limit: 10000,
      offset: 0,
    }

    let qryCodes = queryTaxonomyCodes({
      ...queryCodes,
    });

    dispatch(
      qryCodes
    );
  }

  fetchAffiliated() {
    const { dispatch, providers, clientId } = this.props;

    const { query } = this.state; 

    if (providers?.data?.rows) {
      const providerIds = providers.data.rows.map((provider) => provider.id);
      dispatch(
        queryProviders(
          {
            ...query,
            where: { ...query.where, id: providerIds },
            limit: undefined,
            offset: undefined,
            include: [
              {
                association: 'clients',
                attributes: ['id'],
                required: true,
                through: { attributes: [] }, // don't need the join table data
                where: {
                  id: clientId,
                },
              },
            ],
          },
          API_PROVIDERS_LIST
        )
      );
    }
  }

  

  render() {
    const { classes, providers, affiliatedProviders, user, taxonomyCodes } = this.props;

    const { query, isAdding, newProvider } = this.state;

    const affiliatedProvidersData = StoreUtil.getData(affiliatedProviders);
    const providersData = StoreUtil.getData(providers);
    if (affiliatedProvidersData && providersData?.rows) {
      for (let i = 0; i < providersData.rows.length; i++) {
        providersData.rows[i].affiliated = affiliatedProvidersData.some(
          (provider) => {
            return provider.id === providersData.rows[i].id;
          }
        );
      }
    }

    return (
      <div className={classes.container}>
        <Helmet defer={false}>
        <title>Providers</title>
        </Helmet>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>Providers</Typography>
        </div>

        <ProvidersTabs tab='providers' />
        
        <div className={classes.container}>
          <DynamicTable
            header=
              {
                <div className={classes.headerContainer}>
                  <Typography className={classes.title}>
                    Providers
                  </Typography>
                  {user.is_admin && (
                    <div>
                      <Button
                        color='primary'
                        size='small'
                        className={classes.addProviderButton}
                        onClick={this.newProvider}
                      >
                        <Icon className={classes.leftIcon}>add</Icon>
                        Add Provider
                      </Button>
                    </div>
                  )}
                </div>
              }              
            columns={this.columns}
            taxonomyCodes={taxonomyCodes}
            query={query}
            data={providers}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/providers/${row.id}`}
          />
        </div>

        { isAdding && (<ProviderAddDialog
          open={isAdding}
          provider={newProvider}
          clientId={app.selectedClient}
          onClose={this.closeEditProvider}
          onSave={this.onSaveNew}
          //onExited={this.onExited}
        />
        )}
      </div>
    );
  }

  onSaveNew = (data) => {
    const { dispatch, clientId } = this.props;

    this.setState({
      isAdding: false,
    });

    data.selectedClient = this.props.app.selectedClient;

    delete data.profile_notes;

    let regExp = /^\+1|[^0-9]+/g;

    data.phone = data.phone?.replace(regExp, '');
    data.mobile_phone = data.mobile_phone?.replace(regExp, '');

    dispatch(upsertProvider(data));
    
    this.getLast(clientId, (id) => {
      window.location = `/providers/${id}`;
    })

    //console.info(result);
  //  location.reload();

  };

  closeEditProvider = () => {
    this.setState({
      isAdding: false,
      //editFormError: false,
      //editFormErrorMessage: '',
    });
  };

}

ProvidersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  affiliatedProviders: PropTypes.object,
  user: PropTypes.object.isRequired,
  updateCriteria: PropTypes.func.isRequired,
  taxonomyCodes: PropTypes.object.isRequired,  
};

function mapStateToProps(state) {
  const { providers, app, user, taxonomyCodes } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    providers: StoreUtil.get(providers, StoreUtil.COMMON_TABLE),
    affiliatedProviders: StoreUtil.get(providers, StoreUtil.COMMON_LIST),
    taxonomyCodes: StoreUtil.get(taxonomyCodes, StoreUtil.COMMON_TABLE)
  };
}

const styled = withStyles(styles)(ProvidersPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ProvidersPage };
