/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { showConfirmationDialog } from 'store/actions/systemActions';
import {
  FormControl,
  Select,
  Dialog,
  Icon,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  InputLabel,
  Tab,
  Tabs,
} from '@mui/material';
import {
  MarketMap,
  MarketsSelectList,
  EditMarketDialog,
  SmallIconTextButton,
  ZipCityList,
} from 'components';
import {
  listMarkets,
  upsertMarket,
  removeMarket,
} from 'store/actions/marketsActions';
import { loadAnalytics } from 'store/actions/analyticsActions';

import { StoreUtil,  
  PatientType,
  } from 'doctivity-shared/utils';
import { MarketsUtil, ServiceLineUtil } from 'utils';
import { TagSelector } from 'tags/TagSelector';
import { setMarket } from 'store/actions/appActions';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { TagsUtil } from '../utils';

const styles = (theme) => ({
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogContent: {
    height: 500,
    paddingTop: theme.spacing(2),
  },
  filterDropDown: {
    width: 168,
    marginRight: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  lockIcon: {
    fontSize: '14px',
    verticalAlign: 'middle',
    color: '#999999',
    width: 24,
    minWidth: 24,
    height: '24px',
    lineHeight: '18px',
  },
  tabs: {
    paddingBottom: 0,
  },
  parentServiceline: {
    borderTop: '1px solid #cccccc',
  },
  subServiceline: {
    fontSize: 14,
    paddingLeft: theme.spacing(4),
  },
  tagFilteringOrganization: {
    fontSize: 14,
    fontWeight: 200,
  },
  selectMarketContainer: {
    gap: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    overflow: 'hidden',
    maxHeight: '30rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  marketMapContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  selectMarketHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    height: '24px',
    justifyContent: 'flex-end',
    width: '50%',
    maxWidth: '50%',
    marginLeft: '50%',
  },
  options: {
    textAlign: 'end',
  },
  marketListContainer: {
    width: '50%',
    minWidth: '50%',
    maxHeight: '30rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    maxWidth: '85%',

  },
  tagData: {
    text: 'gray',
    paddingTop: '30px',
    fontSize: '24'
  },
});

class MarketOrTagSelect extends React.Component {
  constructor(props) {
    super(props);
    let selectedFilter = props.filter ? props.filter : undefined;
    if (!selectedFilter) {
      console.log('NO SELECTED FILTER');
      selectedFilter = { type: 'market', market: {}, tag: {} };
    }
    this.state = {
      showTagData: false,
      uniqueProviders: 0,
      uniqueOrganizations: 0,
      marketsEditOpen: false,
      tab: selectedFilter.type === 'market' ? 'markets' : 'tags',
      open: false,
      editMarket: null,
      selectedFilter,
      previousFilter: selectedFilter,
      query: {
        offset: 0,
        limit: 200,
      },
    };

  }
 
  componentDidMount() {
    this.checkTagsState();
    this.props.dispatch(listMarkets(this.props.clientId, true));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.open && this.state.open) {
      this.props.dispatch(listMarkets(this.props.clientId, true));
      if (
        this.props.allowTagSelection !== undefined &&
        !this.props.allowTagSelection &&
        this.state.selectedFilter?.type === 'tag'
      ) {
        this.setState({ selectedFilter: undefined, tab: 'markets' });
      } else {
        this.setState({
          tab: this.state.selectedFilter?.type === 'tag' ? 'tags' : 'markets',
        });
      }
    }
    if (!this.state.open && prevProps.filter !== this.props.filter) {
      this.setState({
        selectedFilter: this.props.filter,
        previousFilter: this.props.filter,
      });
    }
    this.checkTagsState();
    if (
      this.state.selectedFilter?.type === 'market' &&
      Array.isArray(this.props.markets?.data) &&
      this.state.selectedFilter?.market &&
      this.props.markets.data.find(
        (m) => m.id === this.state.selectedFilter.market.id,
      ) !== this.state.selectedFilter.market
    ) {
      if (
        !this.props.markets.data.find(
          (m) => m.id === this.state.selectedFilter.market.id,
        )
      ) {
        this.setState({
          selectedFilter: {
            ...this.state.selectedFilter,
            market: this.getMarket(this.state.selectedFilter.market.id),
          },
        });
      } else {
        this.setState({
          selectedFilter: {
            ...this.state.selectedFilter,
            market: this.props.markets.data.find(
              (m) => m.id === this.state.selectedFilter.market.id,
            ),
          },
        });
      }
    }
  }

  getServicelineIds() {
    const { currentCriteria } = this.props;
    let patientType = currentCriteria;
    let serviceline = currentCriteria.serviceline;
    if (serviceline === 'all' || Array.isArray(serviceline) && serviceline.length === 0) {
      serviceline = ServiceLineUtil.getDefault();
    }
    if (patientType === PatientType.CAPTURED_CLAIMS) {
      if (this.getCodesType()) {
        return null;
      }
      if (!Array.isArray(serviceline)) {
        serviceline = [serviceline];
      }
    } else {
      if (Array.isArray(serviceline) && serviceline.length > 0) {
        serviceline = serviceline[0];
      }
    }
    return serviceline;
  }

  async loadTagData(thisTag) {
    this.props.dispatch(
      loadAnalytics({
        filter: {
          tag_id: thisTag.id,
          client_id: this.props.clientId,
        },
        type: 'TAG_COUNTS',
      }),
    );

    /*
    const servicelineIds = this.getServicelineIds();
    const { claimsType } = this.props.currentCriteria;
    var type = ClaimQueryType.CLAIMS_PER_PROVIDER;
    const { currentCriteria } = this.props;
    const { patientType, //ready, 
      claimSetting, taxonomies } = currentCriteria;

    var options = {
      type,
      filter: {
        serviceline:
          !servicelineIds || servicelineIds.length === 0
            ? ServiceLineUtil.getDefault()
            : servicelineIds,
        codes: null,
        codes_type: null,
        market: undefined,
        tag: { id: thisTag.id },
        location_type: claimSetting === 0 ? undefined : claimSetting,
        patient_type: patientType,
        taxonomies: claimsType === 'providers' ? taxonomies : undefined,
        affiliated_client_id: this.props.clientId,
      },
      opts: this.state.query,
    };

   this.props.dispatch(
      loadAnalytics({
        ...options,
      }),
    );

    
    options = {
     // type,
      filter: {
        serviceline: undefined,
        tag: { id: thisTag.id },
        location_type: claimSetting === 0 ? undefined : claimSetting,
        patient_type:undefined,
        taxonomies: undefined,
        affiliated_client_id: this.props.clientId,
      },
      opts: this.state.query,
    };

    this.props.dispatch(
      loadAnalytics({
        ...options,
        type: ClaimQueryType.CLAIMS_PER_PROVIDER_COUNTS,
      }),
    );

    type = ClaimQueryType.CLAIMS_PER_ORGANIZATION;

    options = {
      type,
      filter: {
        serviceline:
          !servicelineIds || servicelineIds.length === 0
            ? ServiceLineUtil.getDefault()
            : servicelineIds,
        codes: null,
        codes_type: null,
        market: undefined,
        tag: { id: thisTag.id },
        location_type: claimSetting === 0 ? undefined : claimSetting,
        patient_type: patientType,
        taxonomies: claimsType === 'providers' ? taxonomies : undefined,
        affiliated_client_id: this.props.clientId,
      },
      opts: this.state.query,
    };


    this.props.dispatch(
      loadAnalytics({
        ...options,
      }),
    );
    
    options = {
      // type,
       filter: {
         serviceline: undefined,
         tag: { id: thisTag.id },
         location_type: claimSetting === 0 ? undefined : claimSetting,
         patient_type: undefined,
         taxonomies: undefined,
         affiliated_client_id: this.props.clientId,
       },
       opts: this.state.query,
     };
 

    this.props.dispatch(
      loadAnalytics({
        ...options,
        type: ClaimQueryType.CLAIMS_PER_ORGANIZATION_COUNTS,
      }),
    );
    */

  }

  showTagData() {
    const { tagCounts } = this.props;

    let providers = 0;
    let organizations = 0;
    if (tagCounts?.data) {
      providers = tagCounts.data.providers;
      organizations = tagCounts.data.organizations;
    }
    return { uniqueProviders: providers, uniqueOrganizations: organizations };
    /*
    const { providers, organizations, providerCounts, organizationCounts } = this.props;
    var uniqueProviders = 0;
    var uniqueOrganizations = 0;
    var rows = 0;

    console.log('Showing Tag Data');

    uniqueProviders = [];
    if (providerCounts?.data && providerCounts.data.total) {
      rows = providers.data?.rows
      uniqueProviders = rows?.filter((obj, index) => {
        return index === rows.findIndex((o) => obj.provider_id === o.provider_id);
      });

      rows = providers.data?.rows

    }

    uniqueOrganizations = [];
    if (organizationCounts?.data && organizationCounts.data.total) {
      rows = organizations.data?.rows;
      uniqueOrganizations = rows?.filter((obj, index) => {
        return index === rows.findIndex((o) => obj.organization_id === o.organization_id);
      });

      console.log(uniqueOrganizations);

    }

    return { uniqueProviders: uniqueProviders.length, uniqueOrganizations: uniqueOrganizations.length };
    //this.setState({ uniqueProviders: uniqueProviders.length, uniqueOrganizations: uniqueOrganizations.length });
    */
  }


  checkTagsState() {
    if (
      StoreUtil.needsLoadLongCache(this.props.tagNamespaces) ||
      this.props.tagNamespaces.clientId !== this.props.clientId
    ) {
      return this.fetchTags();
    }

    const previousFilter = this.state.previousFilter;
    if (
      StoreUtil.isLoaded(this.props.tagNamespaces) &&
      previousFilter?.tag &&
      previousFilter.tag.id &&
      !previousFilter.tag.name
    ) {
      const tag = TagsUtil.findTag(
        previousFilter.tag.id,
        this.props.tagNamespaces,
      );
      if (tag) {
        this.setState((state) => ({
          previousFilter: {
            ...state.previousFilter,
            tag,
          },
        }));
      } else {
        // this usually means there was a switch of clients and the tag is not accessible from this client
        console.log('tag not found: ', previousFilter.tag.id);
        // lets default to first tag in first namespace
        const tag = TagsUtil.getNamespacesByType(
          this.props.type ?? 'providers',
          this.props.tagNamespaces,
        )?.find((ns) => ns.tags.length > 0)?.tags[0];
        if (tag) {
          if (previousFilter.type === 'tag') {
            this.props.onChange?.({ ...previousFilter, tag });
          }
        } else {
          // no tags available, pick a market
          const market = StoreUtil.isLoaded(this.props.markets)
            ? this.props.markets[0]
            : undefined;
          this.setState({
            previousFilter: {
              ...previousFilter,
              tag: undefined,
            },
            selectedFilter: {
              type: 'market',
              market,
            },
            tab: this.state.selectedFilter?.type === 'tag' ? 'tags' : 'markets',
          });
        }
      }
    }
    if (
      (this.state.tab === 'markets' ||
        this.state.selectedFilter.type === 'market') &&
      StoreUtil.isLoaded(this.props.markets) &&
      !this.state.selectedFilter.market
    ) {
      const market = this.props.markets.data[0];
      if (market) {
        const selectedFilter = {
          type: 'market',
          market: market ?? previousFilter.market,
        };
        this.setState({
          previousFilter: { ...this.state.selectedFilter },
          selectedFilter,
          tab: 'markets',
        });
      }
    }
  }

  fetchTags() {
    this.props.dispatch(listTagNamespacesByClient(this.props.clientId));
  }

  getProviderType() {
    return this.props.currentCriteria.claimsType || 'organizations';
  }

  getName() {
    if (this.state.selectedFilter?.type === 'market') {
      return this.state.selectedFilter.market?.name;
    } else if (this.state.selectedFilter.tag) {
      const tag = TagsUtil.findTag(
        this.state.selectedFilter.tag.id,
        this.props.tagNamespaces,
      );
      return tag?.name;
    }
    return '';
  }

  render() {
    const { classes, allowTagSelection, providers } = this.props;
    const { tab, open, selectedFilter } = this.state;


    let results = {
      'uniqueProviders': 0,
      'uniqueOrganizations': 0
    };

    if (providers.meta.loadStatus === 'STATUS_SUCCESS') {
      console.log('Loading Tag Data');
      results = this.showTagData();
    }


    let isSaveable = false;
    if (selectedFilter?.type === 'market') {
      isSaveable = !!selectedFilter.market;
    } else if (selectedFilter) {
      isSaveable = !!selectedFilter.tag;
    }
    if (!allowTagSelection && tab === 'tags') {
      isSaveable = false;
    }

    return (
      <>
        <FormControl
          variant='outlined'
          className={classes.filterDropDown}
          key='filter-serviceline'
        >
          <InputLabel>Market / Label</InputLabel>
          <Select
            value='filter'
            label='Market / Label'
            open={false}
            onOpen={() => this.setState({ open: true })}
            onClick={() => this.setState({ open: true })}
          >
            <MenuItem value='filter'>{this.getName()}</MenuItem>
          </Select>
        </FormControl>
        {open && (
          <Dialog
            onClose={this.onCancel}
            aria-labelledby='simple-dialog-title'
            open={open}
            fullWidth
            maxWidth='lg'
            scroll='paper'
          >
            <DialogTitle className={classes.dialogTitle}>
              Filter by Geographic Market or Label
              <Tabs
                variant='scrollable'
                className={classes.tabs}
                TabIndicatorProps={{ className: classes.indicator }}
                value={tab}
                onChange={this.onTabChange}
              >
                <Tab
                  className={classes.tabPanel}
                  label='Market'
                  value='markets'
                  disableRipple
                />
                <Tab
                  className={classes.tabPanel}
                  label='Label'
                  value='tags'
                  disableRipple
                />
              </Tabs>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
              {tab === 'markets' &&
                this.state.selectedFilter.market &&
                this.renderMarketSelect(classes)}
              {!!allowTagSelection && tab === 'tags' && this.renderTagSelect(results)}
              {!allowTagSelection &&
                tab === 'tags' &&
                this.renderTagSelectOrganization(classes)}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onCancel} color='primary'>
                Cancel
              </Button>
              <Button
                disabled={!isSaveable}
                onClick={this.onSave}
                variant='contained'
                color='primary'
              >
                Filter
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {this.state.marketsEditOpen && (
          <EditMarketDialog
            open={this.state.marketsEditOpen}
            onClose={this.closeMarketsEditDialog}
            onChange={this.onMarketsChanged}
            onSave={this.onSaveMarket}
            market={this.state.editMarket}
          />
        )}
      </>
    );
  }

  onCancel = () => {
    this.setState({ open: false, selectedFilter: this.state.previousFilter });
  };

  onSaveMarket = (market) => {
    market.client_id = this.props.clientId;
    this.props.dispatch(upsertMarket(market));
    this.closeMarketEditDialog();
  };

  closeMarketEditDialog = () => {
    this.setState({ marketsEditOpen: false, editMarket: null });
  };

  onSave = () => {
    const newState = {};
    if (this.state.tab === 'markets') {
      this.props.dispatch(setMarket(this.state.selectedFilter.market.id));
      newState.type = 'market';
      newState.market = this.state.selectedFilter.market;
      newState.tag = this.state.previousFilter.tag;
    } else {
      newState.type = 'tag';
      newState.tag = this.state.selectedFilter.tag;
      newState.market = this.state.previousFilter.market;
    }

    this.props.onChange?.(newState);
    this.setState({ open: false, previousFilter: newState });
  };

  onTabChange = (_event, tab) => {
    this.setState({ tab });
  };

  renderMarketSelect = (classes) => {
    return (
      <>
        <div className={classes.selectMarketHeader} style={{}}>
          <span
            style={{
              flexGrow: 1,
              lineHeight: '24px',
              verticalAlign: 'middle',
              display: 'inline-block',
              paddingLeft: '8px',
              fontWeight: 500,
              fontFamily: 'Roboto',
              textWrap: 'nowrap',
            }}
          >
            <span
              style={{
                paddingRight: '8px',
                marginRight: this.state.selectedFilter.market.is_private
                  ? 0
                  : '24px',
              }}
            >
              {this.state.selectedFilter.market.is_private && (
                <Icon className={classes.lockIcon}>lock</Icon>
              )}
            </span>
            {this.state.selectedFilter.market.name}
          </span>
          <span style={{ textAlign: 'right', textWrap: 'nowrap' }}>
            <Icon
              className={classes.lockIcon}
              onClick={this.openMarketsEditDialog}
            >
              edit
            </Icon>
            <Icon className={classes.lockIcon} onClick={this.deleteMarket}>
              delete
            </Icon>
          </span>
        </div>

        <div className={classes.selectMarketContainer}>
          <div className={classes.marketListContainer}>
            <MarketsSelectList
              market={this.state.selectedFilter?.market}
              onChange={this.onMarketChanged}
            />
            <div className={classes.options}>
              <SmallIconTextButton
                icon='add'
                text='Market'
                onClick={this.onAddMarket}
              />
            </div>
          </div>
          {this.state.selectedFilter?.market &&
            (this.state.selectedFilter.market.by_postal ? (
              <ZipCityList zipList={this.state.selectedFilter.market.postals} />
            ) : (
              <div className={classes.marketMapContainer}>
                <MarketMap market={this.state.selectedFilter.market} />
              </div>
            ))}
        </div>
      </>
    );
  };


  renderTagSelect = (results) => {

    const { classes } = this.props;
    

    return ( 
      <>
      <div className={classes.container}>
        <TagSelector
        type={this.props.type}
        tagId={this.state.selectedFilter?.tag?.id}
        onChange={this.onSelectedTagChanged}
      />
      </div>

      {this.state.showTagData && (
      <InputLabel className={classes.tagData}>The {this.state.selectedFilter?.tag?.name} label has: <br/><br/><b>{results.uniqueProviders===1 ? results.uniqueProviders + ' Total Provider' : results.uniqueProviders + ' Total Providers'} <br></br>{results.uniqueOrganizations===1 ? results.uniqueOrganizations + ' Total Organization' : results.uniqueOrganizations + ' Total Organizations'}</b><br/><br/><br/>Note: Selected filters may affect how many results are displayed</InputLabel>
      )}
      </>
    );
  };

  renderTagSelectOrganization = () => { 
    return (
      <TagSelector
        type={this.props.type}
        tagId={this.state.selectedFilter?.tag?.id}
        onChange={this.onSelectedTagChanged}
      />
    );
  };

  onSelectedTagChanged = (tag) => {
    this.setState({
      selectedFilter: { type: 'tag', tag },
    });


    this.setState({showTagData: true})

    this.loadTagData(tag);
  };



  getMarket(id = null) {
    const markets = StoreUtil.getData(this.props.markets);
    if (markets && markets.length > 0) {
      return markets.find((market) => market.id === id);
    }
    return null;
  }

  onMarketChanged = (market) => {
    this.setState({
      selectedFilter: { type: 'market', market },
    });
  };

  openMarketsEditDialog = () => {
    this.setState({
      marketsEditOpen: true,
      editMarket: this.state.selectedFilter.market,
    });
  };
  onAddMarket = () => {
    this.setState({ marketsEditOpen: true, editMarket: null });
  };
  closeMarketsEditDialog = () => {
    this.setState({ marketsEditOpen: false });
  };
  deleteMarket = () => {
    this.props.dispatch(
      showConfirmationDialog({
        title: 'Delete Market',
        content:
          'This action will remove the market for all users who can view it, and it cannot be undone.',
        confirmLabel: 'Delete',
        onConfirm: () => {
          this.props.dispatch(
            removeMarket(
              this.state.selectedFilter.market.id,
              this.props.clientId,
            ),
          );
        },
        onCancel: () => {
          // Do nothing
        },
      }),
    );
  };
}

MarketOrTagSelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  tagCounts: PropTypes.object,
  // providerCounts: PropTypes.object,
  organizations: PropTypes.object.isRequired,
  // organizationCounts: PropTypes.object,
  onChange: PropTypes.func,
  clientId: PropTypes.number,
  currentCriteria: PropTypes.object.isRequired,
  marketsClientId: PropTypes.number,
  markets: PropTypes.object,
  market: PropTypes.object,
  tagNamespaces: PropTypes.object,
  filter: PropTypes.object,
  allowTagSelection: PropTypes.bool,
  type: PropTypes.oneOf([
    'all',
    'providers',
    'activity_notes',
    'organizations',
  ]),
};

function mapStateToProps(state) {

  return {
    providers: StoreUtil.get(state.analytics, 'CLAIMS_PER_PROVIDER'),
    organizations: StoreUtil.get(state.analytics, 'CLAIMS_PER_ORGANIZATION'),
    tagCounts: StoreUtil.get(state.analytics, 'TAG_COUNTS'),
    /*
    providerCounts: StoreUtil.get(
      state.analytics,
      'CLAIMS_PER_PROVIDER_COUNTS',
    ),
    organizationCounts: StoreUtil.get(
      state.analytics,
      'CLAIMS_PER_ORGANIZATION_COUNTS',
    ),
    */
    clientId: state.app.selectedClient,
    marketsClientId: state.markets.clientId,

    markets: MarketsUtil.getMarkets(state),
    market: MarketsUtil.getSelectedMarket(state),
    tagNamespaces: StoreUtil.get(
      state.tagNamespaces,
      'API_TAG_NAMESPACES_LIST_BY_CLIENT',
    ),
  };
}

const styled = withStyles(styles)(MarketOrTagSelect);
const connected = connect(mapStateToProps)(styled);
export { connected as MarketOrTagSelect };
