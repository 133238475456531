/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tab,
  Tabs,
  Chip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { queryTaxonomyCodes } from 'store/actions/taxonomyActions';
import { StoreUtil, SpecialtyUtil } from 'doctivity-shared/utils';
import { FavoritesUtil, ActivityLogsUtil } from 'utils';

import {
  DynamicTable,
  AffiliatedIcon,
} from 'components';

import { activityLogsByUserId } from 'store/actions/activityLogsActions';
import { queryProviders } from 'store/actions/providersActions';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { favoritesByType } from 'store/actions/favoritesActions';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: '',
    sortable: false,
    showFilter: false,
    key: 'clients.id',
    type: 'bool',
    format: (value, row) =>{
      return row?.clients?.id > 0 ? <AffiliatedIcon /> : ''
    },
    style: { width: 16 },
  },
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
  },
  {
    label: 'NPI',
    key: 'npi',
  },
  {
    label: 'Specialty',
    key: 'taxonomy.classification',
    filterFields: ['primary_taxonomy'],

    format: (classification, row) => {
      return SpecialtyUtil.getNameFromTaxonomy(row.taxonomy);
    },
    specialtySearch: true
  },
  {
    label: 'City',
    key: 'location.city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'location.state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
];

// this controls which columns are displayed and how they are looked up in data
const columnstemp = [
  {
    label: '',
    sortable: false,
    showFilter: false,
    key: 'clients.id',
    type: 'bool',
    format: (value, row) =>{
      return row?.clients?.id > 0 ? <AffiliatedIcon /> : ''
    },
    style: { width: 16 },
  },
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
  },
  {
    label: 'NPI',
    key: 'npi',
  },
  {
    label: 'Specialty',
    key: 'taxonomy.classification',
    filterFields: ['primary_taxonomy'],

    format: (classification, row) => {
      return SpecialtyUtil.getNameFromTaxonomy(row.taxonomy);
    },
    specialtySearch: true
  },
  {
    label: 'City',
    key: 'location.city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'location.state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
];

const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    flex: 1,
    color: theme.palette.primary.main,
  },
  affiliatedLabel: {
    fontSize: 14,
    fontWeight: 300,
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minHeight: 420,
  },
  selectedProviders: {
    minHeight: 32 + parseInt(theme.spacing()),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '& > *': {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
});

class FindProviderDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterAffiliated: false,
      providerCategory: props.onMultiSelect ? 'suggested' : 'favorites',
      query: {
        attributes: [
          'id',
          'npi',
          'first_name',
          'last_name',
          'middle_name',
          'location_id',
        ],
        where: {},
        include: [
          {
            association: 'taxonomy',
            attributes: ['id', 'code', 'specialization', 'classification','doctivity_name'],
          },
          {
            association: 'location',
            attributes: [
              'id',
              'name',
              'address1',
              'address2',
              'city',
              'state',
              'postal_code',
              'organization_id',
            ],
            include: [
              { association: 'organization', attributes: ['id', 'name'] },
            ],
          },
          {
            association: 'clients',
            attributes: ['id'],
            required: false,
            duplicating: false,
            through: { attributes: [],
            where: {
              ClientId: props.clientId,
            }},
          },
        ],
        limit: 10,
        offset: 0,
        order: [['id', 'ASC']],
        raw: true,
        nest: true,
      },
      selectedSmartProviders: props.provider ? [props.provider] : [],
    };
  }

  componentDidMount() {
    const { providers } = this.props;

    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }

    let values = JSON.parse(sessionStorage.getItem('selectedSpecialties'));

    if (values && values.length > 0) {
      this.fetchSpecialties(values);
    } else {
      this.taxonomyCodes = [];
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const {
      dispatch,
      clientId,
      userId,
      selectedContactId,
      selectedLocationId,
      selectedLabelId,
      selectedProviderIds
    } = this.props;

    const {
      filterAffiliated,
      query,
      providerCategory,
      selectedSmartProviders,
    } = this.state;

    const include = query.include;
    const clientsIndex = include.findIndex(
      (inc) => inc.association === 'clients',
    );

    if (filterAffiliated) {
      if (clientsIndex >= 0) {
        include[clientsIndex].required = true;
      }
    } else {
      if (clientsIndex >= 0) {
        include[clientsIndex].required = false;
      }
    }
    switch (providerCategory) {
      case 'suggested':

        let providersArr = selectedProviderIds;
        
        if (selectedSmartProviders.length > 0 && providersArr) {
          providersArr = providersArr.concat(selectedSmartProviders.map((p) => p.id));
        }

        dispatch(
          loadAnalytics({
            type: 'PROVIDERS_SMART_SUGGESTIONS',
            filter: {
              client_id: clientId,
              user_id: userId,
              selectedProviderId: providersArr,
              selectedContactId,
              selectedLocationId,
              selectedLabelId,
            },
            opts: query,
          }),
        );

        // what if there are no providers, contacts, locations or labels
        if (!providersArr || providersArr.length === 0) {
          dispatch(
            activityLogsByUserId(userId, 'Providers', clientId, {
              ...query,
              include,
            }),
          );
          
       
        }
        break;
      case 'favorites':
        dispatch(
          favoritesByType(clientId, userId, 'Providers', { ...query, include }),
        );
        break;
      case 'recent':
        dispatch(
          activityLogsByUserId(userId, 'Providers', clientId, {
            ...query,
            include,
          }),
        );
        break;
      default:
        dispatch(
          queryProviders({
            ...query,
            include,
          }),
        );
        break;
    }
  }

  fetchSpecialties(values) {
    const { dispatch } = this.props;

    let queryCodes = {
      where: { code: values},
      order: [['code', 'ASC']],
      limit: 10000,
      offset: 0,
    }

    let qryCodes = queryTaxonomyCodes({
      ...queryCodes,
    });

    dispatch(
      qryCodes
    );
  }

  render() {
    const {
      classes,
      smartProviders,
      favorites,
      recents,
      provider,
      providers,
      open,
      onMultiSelect,
      taxonomyCodes
    } = this.props;

    const { query, providerCategory, selectedSmartProviders } = this.state;

    let mobileColumns = columns;
    if (window.innerWidth < 900) {
      mobileColumns[0].style = { width: 180 };
      mobileColumns[1].style = { width: 180 };
    } else {
      mobileColumns[0].style = {};
      mobileColumns[1].style = {};
    }

    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle>Find a Provider</DialogTitle>
        <DialogContent dividers>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            value={providerCategory}
            onChange={this.onTabChange}
          >
            {onMultiSelect && (
              <Tab label='Suggested' value='suggested' disableRipple />
            )}
            {!onMultiSelect && (
              <Tab label='Favorites' value='favorites' disableRipple />
            )}
            <Tab label='Recent' value='recent' disableRipple />
            <Tab label='All' value='all' disableRipple />
            <Tab label='Affiliated' value='affiliated' disableRipple />
          </Tabs>
          {onMultiSelect && (
            <div className={classes.selectedProviders}>
              {selectedSmartProviders.map((p) => (
                <Chip
                  label={`${p.last_name}, ${p.first_name}`}
                  onDelete={
                    provider && p.id === provider.id
                      ? undefined
                      : () => {
                          this.onDeleteProvider(p);
                        }
                  }
                  variant='outlined'
                  key={p.id}
                />
              ))}
            </div>
          )}
          <div className={classes.filters}>
            <Typography className={classes.instructions}>
              Filter and select the provider
            </Typography>
          </div>
          <div className={classes.table}>
            {providerCategory === 'suggested' && (
              <>
                <DynamicTable
                  showFilters={false}
                  columns={columnstemp}
                  query={query}
                  data={smartProviders?.data?.length>0 ? smartProviders : recents}
                  multiSelectedRows={selectedSmartProviders}
                  noDataMessage='No providers found.'
                  onQueryChange={this.onQueryChange}
                  onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                  onMultiSelect={
                    onMultiSelect ? this.onSmartSuggestionSelected : undefined
                  }
                  ContainerType={React.Fragment}
                  taxonomyCodes={taxonomyCodes}
                />
              </>
            )}
            {providerCategory === 'favorites' && (
              <DynamicTable
                columns={mobileColumns}
                query={query}
                data={favorites}
                multiSelectedRows={selectedSmartProviders}
                noDataMessage='No providers found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
                taxonomyCodes={taxonomyCodes}
              />
            )}
            {providerCategory === 'recent' && (
              <DynamicTable
                columns={mobileColumns}
                query={query}
                data={recents}
                multiSelectedRows={selectedSmartProviders}
                noDataMessage='No providers found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
                className={classes.table}
                taxonomyCodes={taxonomyCodes}
              />
            )}
            {providerCategory === 'affiliated' && (
              <DynamicTable
                columns={mobileColumns}
                query={query}
                data={providers}
                multiSelectedRows={selectedSmartProviders}
                noDataMessage='No providers found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
                className={classes.table}
                taxonomyCodes={taxonomyCodes}
              />
            )}
            {providerCategory === 'all' && (
              <DynamicTable
                columns={mobileColumns}
                query={query}
                data={providers}
                multiSelectedRows={selectedSmartProviders}
                noDataMessage='No providers found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
                taxonomyCodes={taxonomyCodes}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
          {onMultiSelect && (
            <Button
              onClick={this.onSaveMultiSelect}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  onCancel = () => {
    const { onSelect, onMultiSelect } = this.props;

    if (onSelect) {
      onSelect(null);
    } else if (onMultiSelect) {
      onMultiSelect(null);
    }
  };

  onRowClick = (row) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(row);
    }
  };

  onSaveMultiSelect = () => {
    const { onMultiSelect } = this.props;
    const { selectedSmartProviders } = this.state;

    if (onMultiSelect) {
      onMultiSelect(selectedSmartProviders);
    }
  };

  onTabChange = (_event, newValue) => {
    const { query } = this.state;

    this.setState(
      {
        providerCategory: newValue,
        query: { ...query, where: {} },
        filterAffiliated: newValue === 'affiliated',
      },
      this.fetchData.bind(this),
    );

    sessionStorage.removeItem('selectedSpecialties');

    let col = columns.find((c) => c.specialtySearch);

    col.customFilterLabel = '';

  };

  onSmartSuggestionSelected = (selectedProviders) => {
    const shouldFetch = true; //selectedProviders.length <= 1;
    this.setState(
      { selectedSmartProviders: selectedProviders },
      () => shouldFetch && this.fetchData(),
    );
  };

  onDeleteProvider = (provider) => {
    const { selectedSmartProviders } = this.state;

    const newSelectedProviders = selectedSmartProviders.filter(
      (selected) => selected.id !== provider.id,
    );

    this.setState(
      {
        selectedSmartProviders: newSelectedProviders,
      },
      //() => newSelectedProviders.length === 0 && this.fetchData(),
      () => this.fetchData(),
    );
  };
}

FindProviderDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  smartProviders: PropTypes.object.isRequired,
  recents: PropTypes.object.isRequired,
  providers: PropTypes.object.isRequired,
  favorites: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  open: PropTypes.bool.isRequired,
  provider: PropTypes.object,
  onMultiSelect: PropTypes.func,
  selectedProviderIds: PropTypes.object,
  selectedContactId: PropTypes.number,
  selectedLocationId: PropTypes.number,
  selectedLabelId: PropTypes.oneOf([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  taxonomyCodes: PropTypes.object.isRequired,  
};

function mapStateToProps(state) {
  const { providers, favorites, activityLogs, analytics, taxonomyCodes } = state;

  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  return {
    userId,
    providers: StoreUtil.get(providers, StoreUtil.COMMON_TABLE),
    recents: ActivityLogsUtil.getProvidersFromLogs(activityLogs, userId),
    smartProviders: StoreUtil.get(analytics, 'PROVIDERS_SMART_SUGGESTIONS'),
    favorites: FavoritesUtil.getProvidersFromFavorites(
      favorites,
      clientId,
      userId,
    ),
    taxonomyCodes: StoreUtil.get(taxonomyCodes, StoreUtil.COMMON_TABLE)
  };
}

const styled = withStyles(styles)(FindProviderDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as FindProviderDialog };
