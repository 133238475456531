import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  StoreUtil, SpecialtyUtil
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';

import { Icon, Typography, Tooltip } from '@mui/material';

import {
  DynamicTable,
  ReferrerSparkline,
  ProvidersTabs,
} from 'components';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';
import { Helmet } from 'react-helmet';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
});

class ReferringProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    // this controls which columns are displayed and how they are looked up in data
    this.columns = [
      {
        label: '',
        key: 'is_affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (is_affiliated) => {
          if (is_affiliated) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Provider
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Last Name',
        key: 'referrer.last_name',
        fulltext: true,
      },
      {
        label: 'First Name',
        key: 'referrer.first_name',
        fulltext: true,
      },
      {
        label: 'NPI',
        key: 'referrer.npi',
        style: { minWidth: 80, verticalAlign: 'top' },
      },
      {
        label: 'Specialty',
        key: 'referrer.taxonomy.classification',

        format: (classification, row) => {
          return SpecialtyUtil.getNameFromTaxonomy(row.taxonomy);
        },
      },
      {
        label: 'Referrals',
        key: 'count_referrer_id',
        style: { width: 100 },
      },
      {
        label: 'Trend',
        key: 'referrer_id',
        format: (id) => {
          if (id) {
            return <ReferrerSparkline providerId={id} />;
          }
          return 'nope';
        },
        style: { width: 60 },
        sortable: false,
      },
    ];

    this.state = {
      query: {
        limit: 25,
        offset: 0,
        order: [['count_referrer_id', 'DESC']],
      },
    };
  }

  componentDidMount() {
    const { providers } = this.props;

    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (
      this.props.app.selectedClient !== prevProps.app.selectedClient ||
      this.props.app.dateRange !== prevProps.app.dateRange
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    dispatch(
      loadAnalytics({
        type: 'TOP_REFERRERS',
        filter: {
          client_id: this.props.app.selectedClient,
          from: this.props.app.dateRange.start,
          to: this.props.app.dateRange.end,
        },
        opts: {
          limit: this.state.query.limit,
          offset: this.state.query.offset,
          order: this.state.query.order,
        },
      })
    );
  }

  render() {
    const { classes, providers } = this.props;

    const { query } = this.state;

    return (
      <div className={classes.container}>
      <Helmet defer={false}>
      <title>Providers</title>
      </Helmet>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>Providers</Typography>

      </div>
        <ProvidersTabs tab='referring_providers' />
        <div className={classes.container}>
          <DynamicTable
            header='Referring Providers'
            showFilters={false}
            columns={this.columns}
            idKey='referrer_id'
            query={query}
            data={providers}
            onQueryChange={this.onQueryChange}
            getRowHref={this.getRowHref}
          />
        </div>
      </div>
    );
  }

  getRowHref = (row) => {
    return `/providers/${row.referrer_id}`;
  };

  onQueryChange = (query) => {
    this.setState({ query }, () => {
      this.fetchData();
    });
  };
}

ReferringProvidersPage.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    app,
    analytics,
  } = state;

  return {
    app,
    providers: StoreUtil.get(analytics, 'TOP_REFERRERS'),
  };
}

const styled = withStyles(styles)(ReferringProvidersPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ReferringProvidersPage };
