import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { SpecialtySearch } from 'components';
//import MaskedPhoneNumberFormat from 'components/material-form/components/MaskedPhoneNumberFormat';
import NumberFormat from 'react-number-format';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import axiosInstance from 'utils/axiosUtil';
import {
  Button,
//  Typography,
  
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';

const styles = (theme) => ({
  formwrapper: {
    margin: '15px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    flex: '1',
    margin: '10px'
  }, 

  column80: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
    flex: '1',
    margin: '10px'
  }, 

  column10: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '10%',
    justifyContent: 'center',
    flex: '1',
    margin: '10px'
  }, 
  editButton: {
    color: theme.palette.secondary.main,
  },
});

class ProviderEditDialog extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      email: '',
      id: 0,
      business_name: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      npi: null,
      credential: null,
      phone: null,
      mobile_phone: null,
      primary_taxonomy: null,
      taxonomy: null,
      is_affiliated: false,
      is_specialist: false,
      rvu_goal: null
    };

  }

  componentDidMount() {
    this.resetData();
  }



  componentDidUpdate() {
    if (this.props.provider && this.props.provider.id && !this.state.id) {
      this.resetData(this.props.provider);
    }
  }

  npiExists(npi, id, callback) {
    axiosInstance
      .get('/Providers', {
        params: {
          opts: {
            where: {
              npi: { $like: npi }
            },
          },
        },
      })
      .then((response) => {
        if (response.data && response.data.length > 0 && response.data[0].id != id) {
          callback(true);
        } else {
          callback(false);
        }
      });
  }

  resetData(data) {
    const newState = {
      email: '',
      id: 0,
      business_name: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      npi: null,
      credential: null,
      phone: null,
      mobile_phone: null,
      primary_taxonomy: null,
      taxonomy: null,
      is_affiliated: false,
      is_specialist: false,
      rvu_goal: null,
      
    };
    if (data) {
      newState.email = data.email;
      newState.id = data.id;
      newState.business_name = data.business_name;
      newState.first_name = data.first_name;
      newState.middle_name = data.middle_name;
      newState.last_name = data.last_name;
      newState.npi = data.npi;
      newState.credential = data.credential;
      newState.phone = data.phone;
      newState.mobile_phone = data.mobile_phone;
      newState.primary_taxonomy = data.primary_taxonomy;
      newState.taxonomy = data.taxonomy;
      newState.is_affiliated = data.is_affiliated;
      newState.is_specialist = data.is_specialist;
      newState.rvu_goal = data.rvu_goal;

    }
    this.setState(newState);
  }

  render() {
    const {
      classes,
      open,
      provider,
      admin
    } = this.props;


    const {
      email,
      business_name,
      first_name,
      last_name,
      middle_name,
      npi,
      credential,
      phone,
      mobile_phone,
      taxonomy,
      is_affiliated,
      is_specialist,
      rvu_goal,
      primary_taxonomy
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
       // TransitionProps={{
         // onExited: this.handleExited,
        //}}
      >
        <DialogContent>
          <div className={classes.formwrapper}>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                  <TextField required label='First Name' variant='outlined' value={first_name} disabled={!admin} 
                  onChange={(e) => { this.setState({ first_name: e.target.value }); }}
                  />
                </FormControl>
              </div>
              <div className={classes.column}>
                <FormControl>
                  <TextField label='Middle Name' variant='outlined' value={middle_name} disabled={!admin}
                  onChange={(e) => { this.setState({ middle_name: e.target.value }); }}
                   />
                </FormControl>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                  <TextField required label='Last Name' variant='outlined' value={last_name} disabled={!admin} 
                  onChange={(e) => { this.setState({ last_name: e.target.value }); }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                  <TextField label='Business Name' variant='outlined' value={business_name}
                  onChange={(e) => { this.setState({ business_name: e.target.value }); }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                   <NumberFormat
                    error={this.state.duplicate} 
                    label='NPI'
                    required
                    minLength='10'
                    customInput={TextField}
                    variant='outlined'
                    value={npi}
                    format={'##########'}
                    mask=' '
                    onChange={(e) => { this.setState({ npi: e.target.value }); }}
                    helperText={this.state.duplicate ? 'Duplicate NPI':''}
                  />
                </FormControl>
              </div>
              <div className={classes.column}>
                <FormControl>
                  <TextField label='Credential' variant='outlined' value={credential} 
                  onChange={(e) => { this.setState({ credential: e.target.value }); }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                <NumberFormat
                    label='Phone'
                    customInput={TextField}
                    variant='outlined'
                    value={phone}
                    format={'(###) ###-#### '}
                    mask=' '
                    onChange={(e) => { this.setState({ phone: e.target.value }); }}
                  />
                </FormControl>
              </div>
              <div className={classes.column}>
                <FormControl>
                  <NumberFormat
                    label='Mobile'
                    customInput={TextField}
                    variant='outlined'
                    value={mobile_phone}
                    format={'(###) ###-#### '}
                    mask=' '
                    onChange={(e) => { this.setState({ mobile_phone: e.target.value }); }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                  <TextField label='Email' variant='outlined' value={email}  
                    onChange={(e) => { this.setState({ email: e.target.value }); }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column80}>
                <FormControl> 
                    <TextField 
                        placeholder='Specialty' readonly variant='outlined' value={taxonomy?.doctivity_name ? taxonomy.doctivity_name : (taxonomy?.specialization ? taxonomy.specialization : taxonomy?.classification)} 
                        onClick={() => this.showPicker()}
                    />
                </FormControl>
              </div>

              <div className={classes.column10}>
                <Button
                      variant='text'
                      color='primary'
                      onClick={() => this.showPicker()}
                      className={classes.editButton}
                    >
                      <EditOutlinedIcon className={classes.leftIcon} />
                      Select
                </Button>

                </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <FormControl component='fieldset'>
                  <FormControlLabel
                    control={(
                      <Checkbox
//                        id={id}
                        checked={is_affiliated}
                        onChange={(event) => this.toggleAffiliated(event.target.checked)}
                      />
                    
                    )}
                    label='Affiliated'
                  />
                </ FormControl>
                { this.state.isPicking && (
                  <SpecialtySearch
                    open={true}
                    provider={provider}
                    clientId={app.selectedClient}
                    onClose={this.onClose}
                    onRowClick={(provider) => this.onRowClick(provider)}
                    code={primary_taxonomy}
                  />
                )}
              </div>
              { is_affiliated && (
              <div className={classes.column}>
                <FormControl component='fieldset'>
                  <FormControlLabel
                    control={(
                      <Checkbox
//                        id={id}
                        checked={is_specialist}
                        onChange={(e) => { this.setState({ is_specialist: e.target.checked }); }}
    //                    onChange={(event) => onChange(event.target.checked)}
                      />
                    
                    )}
                    label='Specialist'
                  />
                </ FormControl>

              </div>
              )}
            </div>
            { is_affiliated && (<div className={classes.row}>
              <div className={classes.column}>
                <FormControl>
                  <TextField 
                  error={this.state.error} 
                  label='RVU Goal (1 Year)' type='text' variant='outlined' value={rvu_goal}
                   onChange={(e) => { 
                    const value = e.target.value;
                    console.log(!isNaN(+value)); // true if its a number, false if not
                    this.setState({ error: isNaN(+value), rvu_goal: e.target.value }); 
                  }}
                  helperText={this.state.error ? 'RVU Goal must be numeric or empty':''}
                  />

                </FormControl>
              </div>
              <div className={classes.column}>
              </div>
            </div>
            )}
          </div>

        </DialogContent>
        <DialogActions>
         
              <>
                <Button onClick={() => {this.closeDialog()}} color='primary'>
                  Cancel
                </Button>
                <Button variant='contained' onClick={() => {this.onSave()}} color='primary' disabled={!first_name || !last_name || !npi || npi.trim().length<10 || this.state.error}>
                  Save
                </Button>
              </>
        </DialogActions>
      </Dialog>
    );
  }

  onSave = () =>  {

    let data = {
      id: this.state.id,
      email: this.state.email,
      business_name: this.state.business_name,
      first_name: this.state.first_name,
      middle_name: this.state.middle_name,
      last_name: this.state.last_name,
      npi: this.state.npi,
      credential: this.state.credential,
      phone: this.state.phone,
      mobile_phone: this.state.mobile_phone,
      primary_taxonomy: this.state.primary_taxonomy,
      taxonomy: this.state.taxonomy,
      is_affiliated: this.state.is_affiliated,
      is_specialist: this.state.is_specialist,
      rvu_goal: this.state.rvu_goal
    }

    this.npiExists(data.npi, data.id, (exists) => {
      if (exists) {
        this.setState({'duplicate':true})
      } else {
        this.setState({'duplicate':false})
        this.props.onSave(data);
      }
    });


  }

  

  toggleAffiliated = (checked) => {

    let {is_affiliated, is_specialist} = this.state;

    if (!checked) {
      is_affiliated = false;
    } else {
      is_affiliated = true;
      is_specialist = true;
    }

    this.setState({
      is_affiliated,
      is_specialist
    });

  };
  
  showPicker = () => {
    this.setState({ isPicking: true });
  };
  
  closeDialog = () => {
    this.setState({'duplicate':false})
    this.resetData(this.props.provider); 
    this.props.onClose();
  }

  onClose = () => {
    this.setState({ isPicking: false });
  };

  onRowClick = (row) => {
    let {primary_taxonomy, taxonomy} = this.state;
    primary_taxonomy = row.code;
    taxonomy = row;
    this.setState({ primary_taxonomy, taxonomy, isPicking: false });
  };
}



ProviderEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  provider: PropTypes.object,
  admin: PropTypes.object
};

const styled = withStyles(styles)(ProviderEditDialog);
export { styled as ProviderEditDialog };
