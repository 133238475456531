import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import dotProp from 'dot-prop-immutable';
import {
  Button,
  Typography,
  TextField,  
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
} from '@mui/material';

import { StoreUtil, SpecialtyUtil } from 'doctivity-shared/utils';

import { DynamicTable} from 'components';

import { queryTaxonomy, queryTaxonomyCodes } from 'store/actions/taxonomyActions';

const schema = {
  type: 'object',
  required: [],
};


const uiSchema = {};

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Code',
    key: 'code',
  },
  {
    label: 'Grouping',
    key: 'grouping',
  },
  {
    label: 'Classification',
    key: 'classification',

  },
  {
    label: 'Specialty',
    key: 'specialization',

    format: (specialization, row) => {
      return SpecialtyUtil.getNameFromTaxonomy(row);
    },
  }

];


const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  formwrapper: {
    margin: '15px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    flex: '1',
    margin: '10px'
  }, 
  columnright: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    flex: '1',
    margin: '10px',
    textAlign: 'right',
    color: 'lightgray'
  }, 
  column80: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
    flex: '1',
    margin: '10px'
  }, 
  selectedProviders: {
    minHeight: 32 + parseInt(theme.spacing()),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '& > *': {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
  column10: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '10%',
    justifyContent: 'center',
    flex: '1',
    margin: '10px'
  }, 
  editButton: {
    color: theme.palette.secondary.main,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
  },
  headerCode: {
    fontSize: 18,
    fontWeight: 500

  },
  searchField: {
    marginTop: '20px',
    marginBottom: '0px'
  },

});

class SpecialtySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: {},
        order: [['code', 'ASC']],
        limit: 25,
        offset: 0,
      }      
    };
  };

  componentDidMount() {
    const { taxonomy, values, allowMultiple } = this.props;

    this.setState({ values: values ? values : [] });

    if (StoreUtil.needsLoadNoCache(taxonomy)) {
      this.fetchData();

      if (allowMultiple) {
        this.fetchSpecialties();
      } else {
        this.fetchSpecialty();
      }
    }
  }

  componentDidUpdate(prevProps) {
  
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
      if (prevProps.allowMultiple) {
        this.fetchSpecialties();
      } else {
        this.fetchSpecialty();
      }
    }


  }

  updateSpecialties = (values, data) => {
    console.log(values);
  
    let newValues = data;

    this.setState({values: newValues});
    this.props.onChange(newValues,false);
  }

  updateSpecialty = (data) => {
  
    let newValue = null;
  
    //if (taxonomy && code) { //} && values) {
      newValue = data[0];//.find((c) => c.code === code);
    //}

    //this.setState({values: newValue});
    this.setState({newCode: newValue});
    //this.props.onChange(newValues,true);

  }
 
  fetchData() {
    const { dispatch } = this.props;
    const { query } = this.state;

    let qry = queryTaxonomy({
      ...query,
    });

    dispatch(
      qry
    );


  }

  fetchSpecialties(codes) {
    const { dispatch, values } = this.props;

    let newValues = values;

    if (codes) {
      newValues = codes.map((c) => c.code);
    }

    let queryCodes = {
      where: { code: newValues},
      order: [['code', 'ASC']],
      limit: 1000,
      offset: 0,
    }

    let qryCodes = queryTaxonomyCodes({
      ...queryCodes,
    });

    dispatch(
      qryCodes
    );
  }

    fetchSpecialty() {
    const { dispatch, code } = this.props;

    let queryCodes = {
      where: { code: code},
      order: [['code', 'ASC']],
      limit: 1000,
      offset: 0,
    }

    let qryCodes = queryTaxonomyCodes({
      ...queryCodes,
    });

    dispatch(
      qryCodes
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };


  onClearSearch() {
    let { query } = this.state;


      let filters = {};

      query = dotProp.set(query, 'where', filters);

      this.setState({ query });
      this.setState({ query }, this.fetchData.bind(this));
  }

  onSearchChange(e) {

    const { debounceTimer } = this.state;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer: setTimeout(() => {
      
      let { query } = this.state;

      //console.log(e.target.value);
      //console.log(query);

      let filters = {};
      let filters2 = {};
      filters2['code'] = {$like: '%' + e.target.value + '%'};
      filters2['grouping'] = {$like: '%' + e.target.value + '%'};
      filters2['classification'] = {$like: '%' + e.target.value + '%'};
      filters2['specialization'] = {$like: '%' + e.target.value + '%'};
      filters2['doctivity_name'] = {$like: '%' + e.target.value + '%'};

      filters['$or'] =  filters2;

      query = dotProp.set(query, 'where', filters);

      this.setState({ query });
      this.setState({ query }, this.fetchData.bind(this));
    }, 500)
  }

  onRowSelected = (selectedSpecialty) => {
    const shouldFetch = selectedSpecialty.length <= 1;
    this.setState(
      { values: selectedSpecialty },
      () => shouldFetch && this.fetchData(),
    );
  };

  onDeleteSpecialty = (taxonomy) => {
    const { values } = this.state;

    const newSelectedSpecialties = values.filter(
      (selected) => selected.code !== taxonomy.code,
    );
  
    const shouldFetch = newSelectedSpecialties.length <= 1;

    this.setState(
      { values: newSelectedSpecialties },
      () => shouldFetch && this.fetchData(),
    );
    
  };

  onSaveMultiSelect = () => {
    const { allowMultiple, onChange } = this.props;
    const { values } = this.state;

    if (allowMultiple) {
      this.fetchSpecialties(values);
      onChange(values, true);
    }

    this.onClearSearch();
  };

  onClose = () => {
    this.onClearSearch();
    this.props.onClose();
  }

  
  render() {
    const { open, taxonomy, taxonomyCodes, classes, allowMultiple, code } = this.props;

    const { query, values, newCode } = this.state;


    console.log(taxonomyCodes);

    // only update first time
    if (allowMultiple && (values && values.length > 0 && !values[0].classification) && taxonomyCodes.meta?.loadStatus && taxonomyCodes.meta.loadStatus==='STATUS_SUCCESS') {
      this.updateSpecialties(values, taxonomyCodes.data.rows);
    }

    if (!allowMultiple && code && !newCode && taxonomyCodes?.data?.rows) {
      this.updateSpecialty(taxonomyCodes.data.rows);
    }

    return (
      <Dialog        
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        
        maxWidth='lg'
      >
        <DialogContent
          style={{height:'90vh'}}> 
          
          
          <div className={classes.formwrapper}>
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography className={classes.headerTitle}>
                    {allowMultiple ? 'Select Specialties' : 'Select Specialty'}
                </Typography>
              </div>
            </div>

            {!allowMultiple && (
              <div className={classes.selectedProviders}>
                <Chip
                  label={`${SpecialtyUtil.getNameFromTaxonomy(newCode)}`}
                  variant='outlined'
                  key={code}
                />
              
              </div>
            )}

            {allowMultiple && (
              <div className={classes.selectedProviders}>
                {values?.map((s) => (
                  <Chip
                    label={`${SpecialtyUtil.getNameFromTaxonomy(s)}`}
                    onDelete={
                      taxonomy && s.code === taxonomy.code
                        ? undefined
                        : () => {
                            this.onDeleteSpecialty(s);
                          }
                    }
                    variant='outlined'
                    key={s.id}
                  />
                ))}
              </div>
            )}

            <TextField fullWidth id='outlined-search' 
              className={classes.searchField} placeholder='Search for code, grouping, classification or specialty' type='search' 
              onChange = {(e) => this.onSearchChange(e)}
            />
          </div>

          <div className={classes.container}>
            <DynamicTable
              title='Specialties'
              dialogTitle='Specialties'
              allowNew={false}
              columns={columns}
              dialogSchema={schema}
              dialogUISchema={uiSchema}
              query={query}
              data={taxonomy}
              multiSelectedRows={values}
              onQueryChange={this.onQueryChange}
              onRowClick={!allowMultiple ? (row) => this.props.onRowClick(row) : undefined}
              onMultiSelect={allowMultiple ? this.onRowSelected : undefined }
              showFilters={false}
            />
          </div>
        </DialogContent>
        <DialogActions>
         
              <>
                <Button onClick={this.onClose} color='primary'>
                  Cancel
                </Button>
                {allowMultiple && (
                <Button
                  onClick={this.onSaveMultiSelect}
                  variant='contained'
                  color='primary'
                >
                  {values?.length === 0 ? 'Select All' : 'Confirm'}
                </Button>
              )}
              </>
        </DialogActions>
      </Dialog>
    );
  }
}

SpecialtySearch.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.object,
  code: PropTypes.object.isRequired,
  taxonomy: PropTypes.object.isRequired,
  taxonomyCodes: PropTypes.object.isRequired,
  isPicking: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  values: PropTypes.object
};

function mapStateToProps(state)
 {
  
  const {
    taxonomy,
    taxonomyCodes,
    app,
  } = state;


  return {
    app,
    taxonomy: StoreUtil.get(taxonomy, StoreUtil.COMMON_TABLE),
    taxonomyCodes: StoreUtil.get(taxonomyCodes, StoreUtil.COMMON_TABLE)
  };
}

const styled = withStyles(styles)(SpecialtySearch);
const connected = connect(mapStateToProps)(styled);
export { connected as SpecialtySearch };

