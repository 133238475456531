import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_TAXONOMY_CODES,
} from 'store/actions/taxonomyActions';

export const taxonomyCodes = (state = {}, action = null) => {
  const result = ReducerUtil.simpleReduce(
    state,
    action,
    API_TAXONOMY_CODES,
  );
  
  if (result.actionConsumed) {
    return result.state;
  }
  return state;
};
